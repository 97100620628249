import React from "react";
import "./Header.css";
import headerImg from "./images/swipe-header.svg";

const Header = () => {
  return (
    <header>
      <img src={headerImg} alt="Go On, Tell Us What You Like" />
    </header>
  );
}
export default Header;