import React from "react";
import { Link } from "react-router-dom";
import "./Intro.css";
import introImg from "./images/intro.png";

const Intro = () => { 
    return (
        <Link to="/swipe">
          <div className="intro">
            <img src={introImg} alt="Get Started" />
          </div>
        </Link>
    );
}

export default Intro;