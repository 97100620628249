import React from "react";
import "./Join.css";
import joinImg from "./images/join.png";

const Join = () => {
  return (
    <div className="join">
    <a 
    href="https://www.chilis.com/register" 
    target="_blank"
    rel="noreferrer">
      <img src={joinImg} alt="Sign Up" />
    </a>
    </div>
  );
}

export default Join;