import React from "react";
import { Link } from "react-router-dom";
import './End.css';
import endImg from "./images/end.png";

const End = ({endClass}) => {
  return (
    <Link to="/join" className={endClass}>
      <img src={endImg} alt="Success" />
    </Link>

  );
};

export default End;