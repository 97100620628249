import React from "react";
import "./Footer.css";
import logo from "./images/chilis-logo.png";

const Footer = () => {

  return (
    <footer>
      
      <img className="footer-logo" src={logo} alt="Chili's logo" />
    </footer>
  );
}
export default Footer;