import React, {useState, useRef, useMemo} from 'react';
import TinderCard from 'react-tinder-card';
import './TinderCards.css';
import Header from './Header';
import Footer from './Footer';
import End from './End';
import app1 from './images/app1.png';
import app2 from './images/app2.png';
import app3 from './images/app3.png';
import app4 from './images/app4.png';
import app5 from './images/app5.png';
import app6 from './images/app6.png';
import app7 from './images/app7.png';
import app8 from './images/app8.png';

import footerImg from "./images/swipe-title.png";
import leftArrow from "./images/swipe-left-arrow.png";
import rightArrow from "./images/swipe-right-arrow.png";

function TinderCards() {
  // const [status, setStatus] = useState("");
  const [db] = useState([
    {
      name: 'app8',
      url: app8
    },
    {
      name: 'app7',
      url: app7
    },
    {
      name: 'app6',
      url: app6
    },
    {
      name: 'app5',
      url: app5
    },
    {
      name: 'app4',
      url: app4
    },
    {
      name: 'app3',
      url: app3
    },
    {
      name: 'app2',
      url: app2
    },
    {
      name: 'app1',
      url: app1
    },
  ]);

  const [currentIndex, setCurrentIndex] = useState(db.length - 1)
  //const [lastDirection, setLastDirection] = useState()
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  // const childRefs = useMemo(
  //   () => 
  //     Array(db.length)
  //       .fill(0)
  //       .map((i) => React.createRef()),
  //   []
  // )
  const childRefs = useMemo(() => {
  return Array(db.length)
    .fill(0)
    .map(() => React.createRef())
}, [db.length])

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  // const canGoBack = currentIndex < db.length - 1

  const canSwipe = currentIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    //setLastDirection(direction)
    updateCurrentIndex(index - 1)
    console.log(currentIndex)
  }

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }

  // // increase current index and show card
  // const goBack = async () => {
  //   if (!canGoBack) return
  //   const newIndex = currentIndex + 1
  //   updateCurrentIndex(newIndex)
  //   await childRefs[newIndex].current.restoreCard()
  // }

  return (
    <main>
      <Header />
      <div className="tinderCards">
        <div className='tinderCards__cardContainer'>
          {db.map((character, index) => (
            <TinderCard
              ref={childRefs[index]}
              className='swipe'
              onSwipe={(dir) => swiped(dir, character.name, index)}
              onCardLeftScreen={() => outOfFrame(character.name, index)}
              key={character.name}
              preventSwipe={['up', 'down']}
              >
              <div
              style={{ backgroundImage: `url(${character.url})`}} 
              className="card"/>
            </TinderCard>
          ))}
        </div>
      </div>
      <div className="swipe-buttons">
        <img className="swipe-title" src={footerImg} alt="Swipe To Like" />
        <div className="swipe-arrows">
          <button onClick={() => swipe('left')}>
            <img src={leftArrow} className="swipe-left-arrow" alt="Nope!" />
          </button>
          <button onClick={() => swipe('right')}>
            <img src={rightArrow} className="swipe-right-arrow" alt="Like!" />
          </button>
        </div>
      </div>
      <Footer/>
      <End endClass={currentIndex === -1 ? 'showEnd' : 'hideEnd'} />
    </main>
  )
}

export default TinderCards;