import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import TinderCards from './TinderCards';
import Intro from './Intro';
import Join from './Join';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="*" element={<Intro />}></Route> 
        <Route path="/" element={<Intro />}></Route>
        <Route path="/swipe" element={<TinderCards />}></Route>
        <Route path="/join" element={<Join />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
